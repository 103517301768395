// Global UI Action
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const OPEN_MENU = "OPEN_MENU";
export const OPEN_SUBMENU = "OPEN_SUBMENU";
export const CLOSE_ALL_SUBMENU = "CLOSE_ALL_SUBMENU";
export const CHANGE_THEME = "CHANGE_THEME";
export const CHANGE_RANDOM_THEME = "CHANGE_RANDOM_THEME";
export const CHANGE_MODE = "CHANGE_MODE";
export const CHANGE_GRADIENT = "CHANGE_GRADIENT";
export const CHANGE_DECO = "CHANGE_DECO";
export const CHANGE_BG_POSITION = "CHANGE_BG_POSITION";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_DIRECTION = "CHANGE_DIRECTION";
export const LOAD_PAGE = "LOAD_PAGE";
export const SAVE_FILTERED_MENU = " SAVE_FILTERED_MENU";
export const SET_CONFIRM_NAVIGATION = "SET_CONFIRM_NAVIGATION";

// Tree Table
export const TOGGLE_TREE = "TOGGLE_TREE";

// Redux Form
export const INIT = "INIT";
export const CLEAR = "CLEAR";

// Users
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const SAVE_USER_TOKEN = "SAVE_USER_TOKEN";
export const SAVE_USER_INFO = "SAVE_USER_INFO";
export const SAVE_USER_ROLES = "SAVE_USER_ROLES";
export const UPDATE_USER_IMAGE = "UPDATE_USER_IMAGE";
export const LOGOUT = "LOGOUT";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const SESSION_ID = "SESSION_ID";
export const SOCKET_CONNECTION = "SOCKET_CONNECTION";

// Submit Forms
export const SUBMIT_REQUEST = "SUBMIT_REQUEST";
export const SUBMIT_STARTED = "SUBMIT_STARTED";
export const SUBMIT_PROGRESS = "SUBMIT_PROGRESS";
export const SUBMIT_COMPLETED = "SUBMIT_COMPLETED";
export const SUBMIT_ERROR = "SUBMIT_ERROR";

// Crud Table
export const FETCH_DATA = "FETCH_DATA";
export const ADD_EMPTY_ROW = "ADD_EMPTY_ROW";
export const UPDATE_ROW = "UPDATE_ROW";
export const REMOVE_ROW = "REMOVE_ROW";
export const EDIT_ROW = "EDIT_ROW";
export const SAVE_ROW = "SAVE_ROW";

// Crud Form Table
export const FETCH_DATA_FORM = "FETCH_DATA_FORM";
export const ADD_NEW = "ADD_NEW";
export const CLOSE_FORM = "CLOSE_FORM";
export const SUBMIT_DATA = "SUBMIT_DATA";
export const REMOVE_ROW_FORM = "REMOVE_ROW_FORM";
export const EDIT_ROW_FORM = "EDIT_ROW_FORM";

// Social Media
export const FETCH_TIMELINE_DATA = "FETCH_TIMELINE_DATA";
export const FETCH_COMMENT_DATA = "FETCH_COMMENT_DATA";
export const POST = "POST";
export const POST_COMMENT = "POST_COMMENT";
export const TOGGLE_LIKE = "TOGGLE_LIKE";

// Ecommerce
export const FETCH_PRODUCT_DATA = "FETCH_PRODUCT_DATA";
export const SEARCH_PRODUCT = "SEARCH_PRODUCT";
export const SHOW_DETAIL_PRODUCT = "SHOW_DETAIL_PRODUCT";
export const ADD_TO_CART = "ADD_TO_CART";
export const DELETE_CART_ITEM = "DELETE_CART_ITEM";
export const CHECKOUT = "CHECKOUT";

// Contact
export const FETCH_CONTACT_DATA = "FETCH_CONTACT_DATA";
export const SHOW_DETAIL_CONTACT = "SHOW_DETAIL_CONTACT";
export const HIDE_DETAIL = "HIDE_DETAIL";
export const ADD_CONTACT = "ADD_CONTACT";
export const EDIT_CONTACT = "EDIT_CONTACT";
export const SUBMIT_CONTACT = "SUBMIT_CONTACT";
export const CLOSE_CONTACT_FORM = "CLOSE_CONTACT_FORM";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const TOGGLE_FAVORITE = "TOGGLE_FAVORITE";
export const SEARCH_CONTACT = "SEARCH_CONTACT";

// Chat
export const FETCH_CHAT_DATA = "FETCH_CHAT_DATA";
export const SHOW_CHAT = "SHOW_CHAT";
export const HIDE_CHAT = "HIDE_CHAT";
export const SEND_CHAT = "SEND_CHAT";
export const DELETE_CONVERSATION = "DELETE_CONVERSATION";

// Email
export const FETCH_EMAIL_DATA = "FETCH_EMAIL_DATA";
export const OPEN_MAIL = "OPEN_MAIL";
export const FILTER_MAIL = "FILTER_MAIL";
export const COMPOSE_MAIL = "COMPOSE_MAIL";
export const DISCARD_MESSAGE = "DISCARD_MESSAGE";
export const SEARCH_MAIL = "SEARCH_MAIL";
export const SEND_MAIL = "SEND_MAIL";
export const MOVE_TO = "MOVE_TO";
export const DELETE_MAIL = "DELETE_MAIL";
export const TOGGLE_STARED = "TOGGLE_STARED";

// Calendar
export const FETCH_CALENDAR_DATA = "FETCH_CALENDAR_DATA";
export const ADD_EVENT = "ADD_EVENT";
export const DISCARD_EVENT = "DISCARD_EVENT";
export const SUBMIT_EVENT = "SUBMIT_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";

// Task Board
export const FETCH_BOARD_DATA = "FETCH_BOARD_DATA";
export const ADD_BOARD = "ADD_BOARD";
export const DISCARD_BOARD = "DISCARD_BOARD";
export const SUBMIT_BOARD = "SUBMIT_BOARD";
export const DELETE_BOARD = "DELETE_BOARD";

// Notification Form
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

// Confirmation
export const ADD_CONFIRMATION = "ADD_CONFIRMATION";
export const REMOVE_CONFIRMATION = "REMOVE_CONFIRMATION";

// cachedData
export const RENEW_CACHE = "RENEW_CACHE";
export const SAVE_CACHE = "SAVE_CACHE";

export const SET_ISO_NUMBER = "SET_ISO_NUMBER";

// Add these constants
export const SET_USDT_BALANCE = "SET_USDT_BALANCE";
export const SET_MVR_BALANCE = "SET_MVR_BALANCE";
