import React, { useCallback, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import NotFound from "containers/Pages/Standalone/NotFoundDedicated";
import NotificationsProvider from "components/NotificationsProvider";
import ConfirmationProvider from "components/ConfirmationProvider/ConfirmationProvider";
import ForgotPasswordDedicated from "containers/Pages/Standalone/ForgotPasswordDedicated";
import PasswordRecoveryDedicated from "containers/Pages/Standalone/PasswordRecoveryDedicated";
import Test from "containers/Pages/Test/Test";
import appConfig from "config.json";
import { useDispatch, useSelector, connect } from "react-redux";
import { PrivyProvider } from "@privy-io/react-auth";
import { SmartWalletsProvider } from "@privy-io/react-auth/smart-wallets";
import { getCustomToken } from "utils/auth";

import Auth from "./Auth";
import Application from "./Application";
import LoginDedicated from "../Pages/Standalone/LoginDedicated";
import ThemeWrapper, { AppContext } from "./ThemeWrapper";
import ProtectedRoute from "../../utils/ProtectedRoute";
import WalletProvider from "./WalletWrapper";
import { ADD_NOTIFICATION } from "../../actions/actionConstants";
import Spinner from "../../components/Spinner/Spinner";

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

const App = ({ jwtToken }) => {
  const dispatch = useDispatch();
  const [appState, setAppState] = useState("loading");
  const loggedIn = useSelector((state) =>
    state.getIn(["users", "user", "loggedIn"])
  );
  const getCustomAccessToken = useCallback(async () => {
    const wallet = getCustomToken();
    return wallet;
  }, [getCustomToken, loggedIn, jwtToken]);

  useEffect(() => {
    /**
         * Fetches the config from the backend and sets it in the appConfig to be as a global state.
         * It also sets the appState to "loaded" when the config is fetched.
         * It also sets the appState to "done" when the config is fetched and initialized.
         * @returns {Promise<void>}
         */
    const handleGetConfig = async () => {
      try {
        dispatch({
          type: ADD_NOTIFICATION,
          message: "We are initializing our services.",
        });
        const response = await fetch(
          `${process.env.REACT_APP_RESTAPI}/frontend/setting?frontend_id=${appConfig.identifier}`,
          {
            method: "GET",
          }
        );

        const data = await response.json();
        if (response.ok) {
          appConfig.config = {
            ...appConfig.config,
            ...data,
            loading: false,
          };
        }
      } catch (error) {
        dispatch({
          type: ADD_NOTIFICATION,
          message:
                        "An error occurred while initializing our services. Try to reload.",
        });
      } finally {
        setAppState("loaded");
      }
    };

    handleGetConfig();
  }, []);

  useEffect(() => {
    if (
      appState === "loaded" &&
            appConfig.config?.zerodev_project_id?.length > 0
    ) {
      setAppState("done");
    }
  }, [appState]);

  return appState !== "done" ? (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        flexDirection: "column",
      }}
    >
      <Spinner />
    </div>
  ) : (
    <ThemeWrapper>
      <PrivyProvider
        appId={appConfig.config.bc_privy_app_id}
        config={{
          customAuth: {
            isLoading: !jwtToken,
            getCustomAccessToken,
          },
        }}
      >
        <SmartWalletsProvider>
          <WalletProvider>
            <NotificationsProvider />
            <ConfirmationProvider />
            <AppContext.Consumer>
              {(changeMode) => (
                <Switch>
                  <Route
                    path="/forgot-password"
                    exact
                    component={ForgotPasswordDedicated}
                  />
                  <Route path="/test" exact component={Test} />
                  <Route
                    path="/password-recovery"
                    exact
                    component={PasswordRecoveryDedicated}
                  />
                  <Route
                    path="/login"
                    exact
                    component={LoginDedicated}
                  />
                  <ProtectedRoute
                    path="/"
                    render={(props) => (
                      <Application
                        {...props}
                        changeMode={changeMode}
                      />
                    )}
                    skipRoles
                  />
                  <Route component={Auth} />
                  <Route component={NotFound} />
                </Switch>
              )}
            </AppContext.Consumer>
          </WalletProvider>
        </SmartWalletsProvider>
      </PrivyProvider>
    </ThemeWrapper>
  );
};

export default connect((state) => ({
  jwtToken: state.getIn(["users", "user", "token"]),
}))(App);
