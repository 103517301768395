import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Outer from "../../Templates/Outer";
import Login from "../Users/Login";

const LoginDedicated = () => (
  <Outer>
    <Login />
  </Outer>
);

LoginDedicated.propTypes = {
  jwtToken: PropTypes.string,
};

LoginDedicated.defaultProps = {
  jwtToken: undefined,
};

export default connect((state) => ({
  jwtToken: state.getIn(["users", "user", "token"]),
}))(LoginDedicated);
