import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useMemo,
} from "react";
import { connect } from "react-redux";
import { usePrivy } from "@privy-io/react-auth";
import { sepolia } from "wagmi/chains";
import { http, createPublicClient } from "viem";
import ApiConfig from "utils/services/API/index";
import { useSmartWallets } from "@privy-io/react-auth/smart-wallets";
import appConfig from "config.json";

const WalletContext = createContext({});

// eslint-disable-next-line import/no-mutable-exports
export let logoutWallet = null;

const WalletProvider = ({ children, jwtToken }) => {
  const [walletClient, setClient] = useState();
  const [account, setAccount] = useState();
  const [isConnecting, setIsConnecting] = useState(false);
  const { ready, authenticated, user, logout } = usePrivy();
  const { client } = useSmartWallets();

  const publicClient = useMemo(
    () =>
      createPublicClient({
        chain: sepolia,
        transport: http(appConfig.config.alchemy_rpc_url),
      }),
    []
  );

  const connectWallet = async () => {
    try {
      setIsConnecting(true);
      if (!user?.linkedAccounts) {
        throw new Error("No user accounts available");
      }
      const smartWallet = user.linkedAccounts.find(
        (userAccount) => userAccount.type === "smart_wallet"
      );
      if (!smartWallet?.address) {
        throw new Error("No smart wallet found");
      }
      setClient(client);
      setAccount(smartWallet.address);
    } catch (err) {
      console.error("Error connecting wallet:", err);
      throw err;
    } finally {
      setIsConnecting(false);
    }
  };

  const disconnect = async () => {
    setAccount(undefined);
    setClient(undefined);
    await logout();
  };

  const postWalletAddress = async () => {
    if (account) {
      await ApiConfig.post(
        `/bank/assign-wallet-address?walletAddress=${account}`
      );
    }
  };

  useEffect(() => {
    logoutWallet = disconnect;
  }, [disconnect]);

  // Connect wallet when user is available
  useEffect(() => {
    if (authenticated && ready && !account && user) {
      connectWallet();
    }
  }, [authenticated, ready, account, user]);

  useEffect(() => {
    postWalletAddress();
  }, [account]);

  // Memoize the context value
  const contextValue = useMemo(
    () => ({
      ready,
      authenticated,
      user,
      logout,
      walletClient,
      disconnect,
      connectWallet,
      publicClient,
      isConnecting,
      account,
      jwtToken,
    }),
    [
      ready,
      authenticated,
      user,
      logout,
      walletClient,
      disconnect,
      connectWallet,
      publicClient,
      isConnecting,
      account,
      jwtToken,
    ]
  );

  return (
    <WalletContext.Provider value={contextValue}>
      {children}
    </WalletContext.Provider>
  );
};

export default connect((state) => ({
  jwtToken: state.getIn(["users", "user", "token"]),
}))(WalletProvider);

export const useWallet = () => useContext(WalletContext);
