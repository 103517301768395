import { store } from "../index";

export const getCustomToken = () => {
  try {
    const state = store.getState();
    const token = state.getIn(["users", "user", "token"]);
    return token || undefined;
  } catch (error) {
    console.error("Error getting custom token:", error);
    return undefined;
  }
};
